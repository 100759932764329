<template>
  <div style="padding:0 10px;">
    <p>仅展示2023年8月29日以后的数据。<router-link :to="{name: 'share.check.data'}">详情数据点此查看</router-link></p>

      <el-row :gutter="5">
        <el-col style="margin-bottom: 5px;" :span="6" :lg="6" :xs="12" :xl="4" v-for="(item, index) in tableData" :key="index">
          <el-card class="box-card">
            <template #header>
              <b v-text="item.name"></b>
              <br>
            </template>
            <div>
              <el-descriptions column="1">
                <el-descriptions-item label="券数">{{ item.coupon }}</el-descriptions-item>
                <el-descriptions-item label="单数">{{ item.order }}</el-descriptions-item>
                <el-descriptions-item label="金额">{{ item.price }}</el-descriptions-item>
              </el-descriptions>
            </div>
          </el-card>
        </el-col>
      </el-row>
  </div>
</template>

<script>
import {apiGetCheckTotalData} from '@/api/shareWechatTaskApi'
export default {
  data() {
    return {
      tableData: [],
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData () {
      apiGetCheckTotalData().then(data => {
        this.tableData = data
      })
    }
  }
}
</script>

<style scoped>

.el-descriptions__body .el-descriptions__table:not(.is-bordered) .el-descriptions__cell {
  padding-bottom: 0 !important;
}
</style>
